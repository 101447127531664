/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.careerpay {
  border-radius: 4px;
  border: 1px solid;
  font-style: italic;
  /* box-shadow: 2px 2px 5px purple; */
}

.logo-img {
  height: 300px;
  width: 600px;
}

.apple-badge {
  height: 100px;
  width: 300px;
}

.android-badge {
  height: 150px;
  width: 375px;
}

.btn-cpay {
  background-color: #ab6ab5;
  color: white;
}

.btn-cpay:hover {
  background-color: #ffa500;
  color: #000;
}

.cp-text {
  color: #ab6ab5; 
}

.step-number {
  font-size: 95px;
  color: #65479c;
}

.step-title {
  margin-bottom: 20px;
  color: #414141;
  font-weight: 800;
  font-size: 45px;
}

.step-description {
  color: #414141;
  font-size: 30px;
}

.steps-phone {
  width: 450px;
  border-radius: 25px;
}
.step,
.step-two {
  margin-bottom: 75px;
}

.payment-btn {
  cursor: pointer;
  z-index: 999;
}
