.dropdown-toggle {
    font-size: 14px;
}

.nav-link {
    font-size: 1.2em;
}

/* DEVSECOPS/SOFTWARE DEV CARD */
#card-body{
    background-color: #33485B
}
#training-body{
    background-color: #fcfdfd;

}

#card-text{
    color: white;
    max-width:360px;
}

#training-text{
    color: #33485B;
    text-align: center;
    border: solid 2px;
    font-weight: 500;
    max-width:360px;
}
.customerText{
    text-align: center;
    max-width: 600px;
    /* margin-left: 35%; */
    margin-bottom: 10px;

}
.partnersText{
    text-align: center;
    max-width: 600px;
    /* margin-left: 35%; */
    margin-bottom: 100px;
}


#training-title{
   text-align: center;
    color: #33485B;
    font-size: 35px;
    font-weight: 600;
    line-height: 110%;
}
#upcoming-title{
    text-align: center;
     color: #33485B;
     font-size: 35px;
     font-weight: 600;
     line-height: 110%;
 }

/* TRAINING CARD */
#card-body2{
    background-color: #0d6e00;
}
#training-body2{
    background-color: #fcfdfc
}

#card-text2{
    color: rgb(255, 255, 255);
}
.training-text2{
    color: rgb(252, 249, 249);
}

#card-title2{
    color: white;
}
#training-title2{
    color: white;
}
/* AGILE TRANFORMATION CARD */
#card-body4{
    background-color: #800000;
}
/* HEALTHCARE CARD */
#card-body3{
    background-color: #00438d
}
#training-body3{
    background-color: #fbfcfd
}
#card-text3{
    color: white;
}
#training-text3{
    color: white;
}
#upcoming-text{
    /* color: #33485B;
    font-weight: 600;
    max-width:800px;
    justify-content: center; */
    
        text-align: center;
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #33485B;
      
}
#card-title3{
    color: white;
}
#training-title3{
    color: white;
}
#upcoming-title{
    color:#33485B;
    border: solid 2px;
}
#upcoming-body{
    background-color: #fbfcfd;
    align-content: center;
}
#upcomingCard {
    max-width: 100;
    height: 625px;
    margin: 17px;
    margin-bottom: 27px;
    border: solid 2px;
    text-align: center;
  }
  #training-tabs{
    font-weight: bold;
  }
/* #button{
    width: 30%;
    height: 30%
} */

/* #Appddlogo
 {
     width:165px;
     padding:15px;
 }

#introCard {
    max-width: 400px;
    height: 650px;
    border-radius: 4px;
    margin: 7px; 
  }
  .button{
      width: 156px;
  } */


  #devsecops{
    text-decoration: center;
  }


  /* styles for contract vehicles cards */
  #cardtitle{
      color: #007BFF;
  }

  #cardtitle2{
      color: black;
  }

  #cardtext{
      color: black;
  }

  #cvbutton{
      height:50px;
  }

  #card{
    background-color:white
  }