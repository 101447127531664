body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-default {
  background-color: #e9e9e9;
}

#capabilitiesButton {
  background-color: #c82333;
  text-align: right;
  border: 0;
}

.brandLogo {
  width: 208px;
  height: 63px;
  margin-right: 1em;
  margin-top: .5em;
  margin-bottom: .5em;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  margin: -0.3em 0 0 0.4em;
  vertical-align: auto;
  border: 1em solid;
  border-width: 0 0.1em 0.1em 0;
  transform: rotateZ(45deg);
}

.card-title{
  font-size: 22px;
}

/* Site Cards */
.sitecard-title{
  color: white;
  font-size: 24px;
  font-weight: 600;
  line-height: 110%;
  font-family: 'Lato', sans-serif;
  letter-spacing: 2px;
  padding-bottom: .5em;
}

.card-text{
  font-family: 'Lato', sans-serif;
  font-size: 1.15em;
}

#introCard {
  max-width: 340px;
  min-height: 355px;
  margin: 6px;
  margin-bottom: 20px;
  border-color: rgb(219, 219, 219);
}

#trainingCard {
  max-width: 325px;
  margin: 7px;
  margin-bottom: 27px;
}

#devSecOpsCard {
  max-width: 340px;
  min-width: 340px;
  margin: 7px;
  margin-bottom: 27px;
  height: auto;
  padding: 25px;
}

.cardBrands {
  width: 200px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.customerLogo {
  width: 120px;
  padding: 10px;
}
.partnerLogo {
  width: 155px;
  padding: 15px;
}
.utsaLogo {
  width: 210px;
  padding: 15px;
}
.pennsylvaniaLogo {
  width: 200px;
  padding: 17px;
}
.thomasLogo {
  width: 220px;
  padding: 15px;
}
.pikespeakLogo {
  width: 200px;
  padding: 15px;
}

.boeingcustomerLogo {
  width: 170px;
}
.usafcustomerLogo {
  width: 135px;
}

.novuscustomerLogo {
  width: 135px;
}

.nav-item {
  color: #c40c0c;
}

.button {
  width: 100%;
  font-weight: 700;
  font-size: large;
}

/* Footer Styles */
.footer {
  background-color: #dedfe0;
}
.footerLogo {
  width: 180px;
  padding-top: 24px;
}
.phoneNumber a {
  font-size: 30px;
  color: #616264;
  font-weight: 600;
}
.footerIcon {
  width: 60px;
  margin: 15px;
}
.footerSocialIcon {
  width: 20px;
  margin: 13px;
  color: #064bb3;
}

.fbSocialIcon {
  width: 13px;
  margin: 5px;
}
.fa-facebook-f {
  color: #86888b;
  font-size: 40px;
  padding: 10px;
}

.fa-facebook-f:hover {
  color: #064bb3;
  font-size: 40px;
}

.fa-instagram {
  color: #86888b;
  font-size: 30px;
}

.fa-instagram:hover {
  color: #064bb3;
}
.list-group-item {
  background-color: transparent;
  border: transparent;
  padding: 0px;
}
.list-group-item-link {
  color: #616264;
}

h5 {
  color: #535455;
}
.sbaTrophy {
  max-width: 250px;
  padding-top: 20px;
}
.sbaLogo {
  width: 80px;
}
.navbar {
  font-size: 14px;
}

/* styles for hero jumbotrons */

.heroTitle {
  font-family: 'Lato', sans-serif;
  font-size: 1.55em;
  max-width: 30em;
}

.heroCardDisplayText {
  font-size: 48px;
  color: white;
  padding: 0px 0px 0px 0px;
}

.aboutUsText {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.aboutUsHeaders {
  /* margin-bottom: 20px; */
  text-align: center;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}

.heroCardDisplayDescription {
  font-size: 21px;
  color: white;
  padding: 25px 0px 0px 100px;
}

.aboutUsHeroImage {
  /* change to correct server, local, staging or production */
  background-image: url(https://appddictionstudio.com/assets/aboutUsHero.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  padding-left: 45px;
  padding-top: 45px;
}

.devSecOpsHeroImage {
  /* change to correct server, local, staging or production */
  background-image: url(https://appddictionstudio.com/assets/devsecops_hero.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  padding-left: 45px;
  padding-top: 45px;
}

.softDevHeroImage {
  /* change to correct server, local, staging or production */
  background-image: url(https://appddictionstudio.com/assets/softdev_hero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  padding-left: 45px;
  padding-top: 45px;
}

.healthcareHeroImage {
  /* change to correct server, local, staging or production */
  background-image: url(https://appddictionstudio.com/assets/healthcarelg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  padding-left: 45px;
  padding-top: 45px;
}

.ContractVehiclesHeroImage {
  /* change to correct server, local, staging or production */
  background-image: url(https://appddictionstudio.com/assets/contractvehicles.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  padding-left: 45px;
  padding-top: 45px;
}
.trainingHeroImage {
  /* change to correct server, local, staging or production */
  background-image: url(https://appddictionstudio.com/assets/traininglarge.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  width: 100%;
  padding-left: 65px;
  padding-top: 70px;
}

.modernizationHeroImage {
  /* change to correct server, local, staging or production */
  background-image: url(https://appddictionstudio.com/assets/transform_hero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 450px;
  padding-left: 45px;
  padding-top: 45px;
}

.LowCodeImage {
  /* change to correct server, local, staging or production */
  background-image: url(https://appddictionstudio.com/assets/nocode_hero.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  padding-left: 45px;
  padding-top: 45px;
}

.DirCPO5197HeroImage {
  /* change to correct server, local, staging or production */
  background-image: url(https://appddictionstudio.com/assets/dir_cpo_5197_lrg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 450px;
  padding-left: 45px;
  padding-top: 45px;
}

.productLogo {
  background-image: url('../public/assets/product.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 130px;
  height: 150px;
  margin-left: 120px;
  margin-bottom: 20px;
} 

.performanceLogo {
  background-image: url('../public/assets/performance.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 130px;
  height: 150px;
  margin-left: 120px;
  margin-bottom: 20px;
}

.lowPriceLogo {
  background-image: url('../public/assets/low-price.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 130px;
  height: 150px;
  margin-left: 120px;
  margin-bottom: 20px;
}

.adaptationLogo {
  background-image: url('../public/assets/adaptation.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 130px;
  height: 150px;
  margin-left: 120px;
  margin-bottom: 20px;
}

.trainLogo {
  background-image: url('../public/assets/train.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 130px;
  height: 150px;
  margin-left: 120px;
} 

.ringsLogo {
  background-image: url('../public/assets/rings.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 130px;
  height: 150px;
}

.coworkersLogo {
  background-image: url('../public/assets/coworkers.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 130px;
  height: 150px;
  margin-left: 120px;
}

.chefLogo {
  background-image: url('../public/assets/chef.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 130px;
  height: 150px;
}

.certificateLogo {
  background-image: url('../public/assets/certificate.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 130px;
  height: 150px;
  margin-left: 120px;
}

.largeGreyNumbers {
  font: var(--unnamed-font-style-normal) normal bold 110px/257px var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--secondary-hover-5a6268);
  text-align: center;
  font: normal normal bold 140px/110px Roboto;
  letter-spacing: 0px;
  color: #5A6268;
  opacity: 1;
  padding-top: 20px;
  margin-left: 10px;
}

@media only screen and (max-width: 600px) {
  .hereToHelpLogos {
    margin: auto;
  }

  .largeGreyNumbers{
    font: normal normal bold 85px/110px Roboto;
  }

  .trainingLogos{
    margin: unset;
  }
}


.lead {
  color: #ffffff;
  font-size: 24px;
}

.leadDark {
  color: #343a40;
  font-size: 24px;
}

.leadGrey {
  color: #707070;
  font-size: 24px;
}

.whiteText-responsive {
  font-size: calc(100% + 1.2vw + 1.2vh);
  color: #ffffff;
  font-weight: 700;
  line-height: 120%;
}

.blackText-responsive {
  font-size: calc(100% + 1.2vw + 1.2vh);
  color: #000000;
  font-weight: 700;
  line-height: 120%;
}

.btnText-responsive {
  font-size: calc(100% + 0.9vw + 0.9vh);
  color: #ffffff;
}

#pills-tab {
  font-size: 20px;
  border-radius: 0px;
  height: 60px;
}

.nav-tabs {
  border-bottom: 0px;
}

.btn-primary-devsecops {
  background-color: #33485b;
  border-radius: 0px;
  color: #ffffff;
  font-size: 24px;
  height: 60px;
  width: 100%;
}
.btn-primary-training {
  background-color: #33485b;
  border-radius: 0px;
  color: #ffffff;
  height: 65px;
  width: 100%;
}
.btn-secondary-devsecops {
  background-color: #7c8690;
  border-radius: 0px;
  color: #ffffff;
  font-size: 24px;
  height: 60px;
  width: 50%;
}
.btn-secondary-training {
  background-color: #7c8690;
  border-radius: 0px;
  color: #ffffff;
  font-size: 24px;
  height: 65px;
  width: 100%;
}

.btn-secondary-devsecops a:link {
  color: #ffffff;
}

.nav-pills .nav-link {
  background-color: #7c8690;
  color: #ffffff;
}

.navbar-light .navbar-nav .nav-link {
  color: #0a2441;
}

/* Colored Pattern Background Divs */
.darkBlueContainer {
  background-color: #33485b;
  padding: 80px;
}
.greenContainer {
  background-color: #0d6e00;
  padding: 80px;
}

.lightGreyContainer {
  background-color: #f2f2f2;
  padding: 50px;
}

.whiteContainer {
  background-color: #ffffff;
  padding-top: 15px;
  padding-bottom: 40px;
}

.darkOrangeContainer {
  background-color: #800000;
  padding: 50px;
}

.darkGreyContainer {
  background-color: #dedfe0;
  padding: 80px;
}

.blueContainer {
  background: #00438d; /* fallback for old browsers */
  padding: 50px;
}

#medServicesTitle {
  color: #00438d;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 900;
}
#medServicesText {
  color: #111111;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: normal;
}

.btn-upcomingCourses {
  background-color: #33485b;
  padding-top: 3px;
  font-size: 15px;
}

.valid-feedback {
  color: aqua;
}

.cardImages {
  width: 220px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

/* Styles for Upcoming.js */
.safeLogo {
  width: 200px;
  height: 64px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
}

.icAgileLogo {
  width: 166px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}
.scrumAllianceLogo {
  width: 180px;
  height: 74px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}
.devopsInstituteLogo {
  width: 180px;
  height: 84px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

.kanbanUniveristyLogo {
  width: 180px;
  height: 74px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
}

.btn-link {
  color: #111111;
  font-weight: 500;
}

#naicscodes {
  background-color: white;
}

.companywideTrainingImage {
  width: 600px;
  height: 400px;
}

/* naics codes tabs */
#PrimaryNaicsCodesTab {
  background-color: #86888b;
  color: white;
  width: 570px;
  height: 70px;
  font-size: 25px;
}

#PrimaryNaicsCodesTab.active {
  background-color: #33485b;
  color: white;
}

#SecondaryNaicsCodesTab {
  background-color: #86888b;
  color: white;
  width: 570px;
  height: 70px;
  font-size: 25px;
}

#SecondaryNaicsCodesTab.active {
  background-color: #33485b;
  color: white;
}

.contractIcons {
  width: 100px;
}

.courseBadges {
  width: 225px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.courseBrands {
  width: 80px;
}
.instructorImage {
  width: 70px;
  border: solid 1px #707070;
  border-radius: 200px;
}
.upcomingCustomerBrands {
  width: 100px;
  height: 84px;
}

.cpsCustomerBrands {
  width: 180px;
  height: 80px;
}

.transformCardIcons {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.transformCardImgs {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.dirCardIcon {
  width: 175px;
}

.downloadIcon {
  width: 200px;
  padding-top: 20px;
}
.cvvIcon {
  width: 30px;
}

.link {
  color: #000000;
  font-weight: 500;
}
.link:hover {
  color: #c2282d;
  text-decoration: none;
}

.introCardButtonWhiteText {
  color: #ffffff;
}

.helpCard {
  max-width: 320px;
}

.helpIcon {
  width: 100px;
}
.receiptTitle {
  font-size: 30px;
  font-weight: 300;
  line-height: 120%;
}

.receiptText {
  font-size: 22px;
  font-weight: 300;
}

.announcement-standard-card {
  background-color: #FFD75E;
  margin-bottom: -66px;
  border: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  
  z-index: 100;
}
.announceTitle {
  font-size: 22px;
  color: #33485b;
  text-decoration: underline;
}

.stars3para {
  font-size: 18px;
  padding: 10px;
}

.contractVpara {
  font-size: 22px;
  line-height: 130%;
}

.grey-subheader {
  color: #707070;
  font: var(--unnamed-font-style-normal) normal bold 35px/46px var(--unnamed-font-family-roboto);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 30px/46px Roboto;
  letter-spacing: 0px;
  opacity: 1;
}

.dirParagraph {
  font-size: 22px;
  line-height: 130%;
}

.dirHeadTitles {
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  color: #07257d;
}
.dirCapaText {
  font-size: 18px;
  line-height: 130%;
}

.dirSubTitles {
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}

.appGetaways {
  width: 80%;
}

.leadTitleText {
  font-size: 40px;
  font-weight: 300;
  line-height: 120%;
}

.leadTitleTextBold {
  font-size: 40px;
  font-weight: 700;
  line-height: 120%;
}

.text-green {
  color: #0b5c00;
  font-weight: 500;
}
.nocode-img {
  height: 300px;
}

.container-why {
  background-color: #ffffff;
}

.container-migrate {
  background-color: #00438d;
}

.why-img {
  height: 550px;
  padding-left: 300px;
}

.text-nocode {
  font-size: 25px;
}

.migrate-container {
  background-color: #f1f2f2;
}

.accordian-why {
  background-color: #d1d1d1;
}

.btn-subscribe {
  background-color: #ea6510;
}

.activeIcon {
  color: #007bff;
  width: 24px;
  height: 24px;
}

.dirParagraph a {
  color: #0526ba;
  text-decoration: underline;
}

.dirCapaText a {
  color: #0526ba;
  text-decoration: underline;
}

.vendorContact {
  color: #0526ba;
  text-decoration: none;
}

.btn-green {
  background-color: #0d6e00;
  color: white;
}

.btn-blue {
  background-color: #00438D;
  color: #ffffff;
}

.phone-number:focus {
  outline: none;
}

.container-health {
  background-color: white;
}

.display-5 {
  font-size: 38px;
  line-height: 1.2;
}

.display-5-bold {
  font-size: 38px;
  font-weight: 700;
  line-height: 1.2;
}

.display-6-bold {
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: -195px;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
  padding-bottom: 21em;
  left: 17%;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 41em;
}
.carousel-item > img {
  position: absolute;
  top: -75px;
  left: 0em;
  min-width: 100%;
}

.carousel-indicators {
  bottom: 14em;
  justify-content: left;
  left: 2%;
}

.blackText {
  color: #000000;
}

.whiteText {
  color: #ffffff;
}
.alert {
  margin-bottom: 0em;
  z-index: 300;
}

.contractVehicleLinks {
  color: #00438D;
  font-size: 18px;
  text-decoration: underline;
}

.contractVehicleTitleLinks {
  color: #00438D;
  text-decoration: underline;
}

.success-green {
  color: #108c00;
}

.text-bio {
  font-size: 18px;
  line-height: 145%;
}